<template>
  <v-container v-if="!loading && myOrder" class="mt-4 " fluid>
    <!-- SIDE MENU -->
    <template v-if="!producerView">
      <div>
        <v-row no-gutters>
          <v-col cols="12" sm="5" md="4" class="pa-0">
            <v-list>
              <v-list-item class="grey lighten-2" three-line>
                <v-list-item-content class="ml-4">
                  <!-- Order ID -->
                  <v-list-item-title>
                    <span
                      v-if="myOrder.orderType === 'later'"
                      class="headline font-weight-bold"
                      >Pre-</span
                    >
                    <span class="headline">Order</span>

                    <span class="subheading">
                      #{{ myOrder.id.substr(0, 4) }}
                    </span>
                    <v-spacer />
                    <span>
                      {{ myOrder.deliveryMethod | capitalize }}
                    </span>
                  </v-list-item-title>
                  <!-- Buyer Name -->
                  <v-list-item-subtitle class="mt-2">
                    <span class="subtitle"
                      >For
                      {{
                        myOrder.buyer.name || myOrder.delivery.dropoff_name
                      }}</span
                    >
                  </v-list-item-subtitle>
                  <!-- Phone Number -->
                  <v-list-item-subtitle class="mt-2">
                    <span v-if="myOrder.buyer.phone" class="caption">{{
                      parsedNumber
                    }}</span>
                    <span v-else class="font-italic caption"
                      >Phone Number Not Available</span
                    >
                  </v-list-item-subtitle>

                  <!-- user address -->
                  <v-list-item-subtitle>
                    <v-icon small class="mr-2">home</v-icon>
                    <span
                      v-if="myOrder.delivery.dropoff_address"
                      class="caption"
                      >{{ myOrder.delivery.dropoff_address }}
                    </span>

                    <span v-else class="font-italic caption"
                      >Address Not Available</span
                    >
                    <v-row align="center"
                      ><v-col
                        v-if="myOrder.delivery.dropoff_address2"
                        cols="6"
                        md="4"
                      >
                        Apt: {{ myOrder.delivery.dropoff_address2 }}
                      </v-col>
                      <v-col v-if="myOrder.delivery.dropoff_address2" cols="6">
                        <i>{{
                          myOrder.delivery.dropoff_address_instruction
                        }}</i>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="d-inline-flex  white" three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="caption font-weight-medium"
                      >Submitted Time:
                    </span>
                    <span class="body-2">{{
                      convertTime(myOrder.submitDate)
                    }}</span>
                  </v-list-item-title>
                  <v-list-item-title v-if="false">
                    <span class="caption font-weight-medium">Order Time:</span>
                    <span class="body-2">{{
                      convertTime(myOrder.deliveryDateTime)
                    }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="myOrder.status === 'accepted'">
                    <span class="caption font-weight-medium"
                      >Accepted Time:
                    </span>
                    <span class="body-2">{{
                      convertTime(myOrder.acceptDate)
                    }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-title v-if="myOrder.delivery.pickup_eta"
                    ><span class="caption font-weight-medium">Pickup ETA:</span>
                    <span class="body-2">{{
                      myOrder.delivery.pickup_eta | formatTimeOnly
                    }}</span>
                  </v-list-item-title>

                  <v-list-item-title v-else-if="myOrder.pickupDateTime"
                    ><span class="caption font-weight-medium"
                      >Pickup Time:
                    </span>
                    <span class="body-1">{{
                      convertTime(myOrder.pickupDateTime)
                    }}</span>
                  </v-list-item-title>

                  <!-- Accepted Time -->

                  <v-list-item-subtitle v-if="myOrder.pickupReadyTime">
                    <span class="caption font-weight-medium"
                      >Marked as ready:
                    </span>
                    <span class="body-2">{{
                      convertTime(myOrder.pickupReadyTime)
                    }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="myOrder.delivery.picked_up_date">
                    <span class="caption font-weight-medium"
                      >Picked up at:
                    </span>
                    <span class="body-2">{{
                      convertTime(myOrder.delivery.picked_up_date)
                    }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="myOrder.delivery.delivered_at">
                    <span class="caption font-weight-medium"
                      >Delivered at:
                    </span>
                    <span class="body-2">{{
                      convertTime(myOrder.delivery.delivered_at)
                    }}</span>
                  </v-list-item-subtitle>

                  <v-row class="caption">
                    <v-col
                      cols="6"
                      v-if="myOrder.delivery.needNapkin === false"
                    >
                      NO Napkin
                      <v-icon color="green">far fa-sticky-note</v-icon></v-col
                    >
                    <v-col
                      cols="6"
                      v-if="myOrder.delivery.needUtensil === false"
                      >NO Utensil
                      <v-icon color="green">restaurant</v-icon></v-col
                    >
                    <v-col
                      v-if="myOrder.delivery.contactless_delivery"
                      cols="8"
                    >
                      Contactless Delivery
                      <v-icon color="green">fas fa-user-times</v-icon>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

              <!-- Ready time + delivery -->
              <v-list-item class="ml-3">
                <v-list-item-content>
                  <v-list-item-title class="mt-4">
                    Status:
                    <span class="subtitle-2">{{
                      myOrder.status.replace('_', ' ') | capitalize
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="ml-3" v-if="isAdmin && myOrder.admin_error">
                <span class="subtitle-2 error--text">{{
                  myOrder.admin_error
                }}</span>
              </v-list-item>
              <v-list-item class="ml-3" v-else-if="myOrder.error">
                <span class="subtitle-2 error--text">{{ myOrder.error }}</span>
              </v-list-item>

              <!-- Notes -->
              <v-list-item class="ml-3" v-if="myOrder.notes">
                <v-list-item-content>
                  <v-list-item-subtitle class="mt-2">
                    <span class="subtitle">NOTES:</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span class="subtitle">{{ myOrder.notes }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- Reject button -->
              <template v-if="myOrder.status === 'new'">
                <v-list-item class="my-2">
                  <v-list-item-action>
                    <v-btn
                      x-large
                      color="green"
                      class="white--text"
                      :disabled="isAcceptDisabled"
                      depressed
                      @click.stop="changeOrderStatus('accepting')"
                      >Accept Order</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="mt-5">
                  <v-list-item-action>
                    <v-btn
                      small
                      color="red"
                      outlined
                      @click.stop="rejectDialog = true"
                      >Reject Order</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
              <!-- Accepted Order controls -->
              <v-list-item class="mt-3" v-if="myOrder.status === 'accepted'">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row align="space-between" no-gutters>
                      <v-col cols="12" lg="5">
                        <v-btn
                          large
                          v-if="
                            profile.settings &&
                              profile.settings.arrangeThirdPartyDelivery &&
                              myOrder.deliveryMethod === 'delivery'
                          "
                          color="orange white--text"
                          class="caption"
                          :disabled="!showReadyForDelivery()"
                          depressed
                          @click.stop="onReadyForDelivery()"
                          >Send Driver Now</v-btn
                        >

                        <v-btn
                          color="info white--text my-2"
                          @click.stop="onReadyForPickup()"
                          v-else-if="myOrder.deliveryMethod === 'pickup'"
                        >
                          Ready for Pickup</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle class="mt-3" v-if="!isSelfDelivering">
                    <v-row justify="center">
                      <v-col v-for="n in 3" :key="n" cols="4">
                        <v-btn
                          x-small
                          color="light-green"
                          dark
                          depressed
                          @click.stop="changeLeadTime(n * 10)"
                          >+{{ n * 10 }} min</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-for="n in 3" :key="n" cols="4">
                        <v-btn
                          x-small
                          color="orange"
                          dark
                          depressed
                          @click.stop="changeLeadTime(n * -10)"
                          >{{ n * -10 }} min</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-row justify="space-between" class="mb-1">
                      <v-col cols="5" md="3" v-if="canRefund">
                        <RefundOrder
                          :maxRefund="maxRefund"
                          :myOrder="myOrder"
                        />
                      </v-col>
                      <v-col cols="5" md="3" v-if="canRefund">
                        <ExtraCharge :maxCharge="20" :myOrder="myOrder" />
                      </v-col>
                      <v-col cols="5" md="3" v-if="canRefund">
                        <v-btn
                          text
                          small
                          color="info"
                          link
                          @click.stop="$refs.messageCustomerDialog.openDialog()"
                          >Say Thank You</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- track postmates -->
              <div class="ml-2" v-if="track_url">
                <v-card
                  v-if="myOrder.delivery.courier"
                  class="mx-auto"
                  width="600"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">Driver:</v-col>
                      <v-col cols="7">
                        {{ myOrder.delivery.courier.name }}</v-col
                      >
                    </v-row>

                    <v-row>
                      <v-col cols="4">Phone:</v-col>
                      <v-col cols="7">
                        <a
                          :href="'tel:' + myOrder.delivery.courier.phone_number"
                          >{{ myOrder.delivery.courier.phone_number }}</a
                        ></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="4">Status:</v-col>
                      <v-col cols="7">
                        {{ myOrder.delivery.delivery_status | capitalize }}
                        <span
                          v-if="myOrder.delivery.delivery_status != 'delivered'"
                        >
                          <v-btn
                            :href="track_url"
                            target="_blank"
                            small
                            text
                            color="info"
                            >Track</v-btn
                          >
                        </span>
                      </v-col>
                    </v-row>
                    <v-avatar v-if="myOrder.delivery.courier.img_href">
                      <v-img
                        class="elevation-6"
                        :src="myOrder.delivery.courier.img_href"
                      ></v-img
                    ></v-avatar>
                  </v-card-text>
                  <v-card-text v-if="myOrder.delivery.error">
                    <v-alert class="small" type="error" outlined>{{
                      myOrder.delivery.error
                    }}</v-alert>
                  </v-card-text>
                  <v-card-actions class="no-print">
                    <v-btn
                      small
                      color="info"
                      class="mr-3"
                      v-if="isAdmin"
                      @click.stop="onResubmitDelivery"
                      >Resubmit Delivery</v-btn
                    >

                    <span>
                      <v-btn
                        v-if="isAdmin"
                        small
                        color="error"
                        @click.stop="onCancelDelivery"
                        >Cancel Delivery</v-btn
                      >
                    </span>
                  </v-card-actions>
                </v-card>
              </div>
            </v-list>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- Order Information -->
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">QTY</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in myOrder.products"
                    :key="item.dish.id"
                    valign="top"
                  >
                    <!-- Order amounts -->
                    <td>
                      <span class="body-1">{{ item.orderAmount }} x</span>
                    </td>

                    <!-- order titles -->
                    <td>
                      <span class="title font-weight-medium">{{
                        item.dish.name
                      }}</span>
                      <!-- special instructions -->
                      <tr v-if="item.specialInstruction">
                        <span class="caption font-italic"
                          >*{{ item.specialInstruction }}</span
                        >
                      </tr>
                      <v-row no-gutters>
                        <v-col cols="12" class="ml-6"
                          ><view-modifiers :modifiers="item.modifiers"
                        /></v-col>
                      </v-row>

                      <template v-if="false">
                        <tr
                          v-for="modifier in modifierList(item.modifiers)"
                          :key="modifier.id"
                        >
                          <td>
                            <span class="caption">{{ modifier.name }}</span>
                          </td>
                        </tr>
                      </template>
                    </td>

                    <!-- order price -->
                    <td>
                      <span class="body-1 font-weight-light">{{
                        item.dish.price | currency
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- price total -->
            <v-row justify="end" class="mt-2">
              <v-col cols="3">
                <v-icon
                  class="ml-4"
                  color="grey lighten-1"
                  @click.stop="$refs.orderFeeDialog.openDialog()"
                  >info</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="7">
                <span class="body-1">Subtotal:</span>
              </v-col>
              <v-col cols="3">
                <span class="body-1">{{ all_fees.sub_total | currency }}</span>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="7">
                <span class="body-1">Tips:</span>
              </v-col>
              <v-col cols="3">
                <span class="body-1">{{
                  all_fees.producer_tip | currency
                }}</span>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="7">
                <span class="body-1 font-weight-medium">Total:</span>
              </v-col>
              <v-col cols="3">
                <span class="body-1 font-weight-medium">{{
                  all_fees.producer_total | currency
                }}</span>
              </v-col>
            </v-row>

            <!-- control panel -->
          </v-col>
        </v-row>

        <v-row justify="end">
          <template v-if="isAdmin">
            <v-col cols="6" sm="3" class="my-10">
              <v-btn
                large
                dark
                @click="producerView = !producerView"
                color="pink"
                >Yay I'm Admin</v-btn
              >
            </v-col>
          </template>
          <v-col cols="6" sm="1">
            <v-btn icon to="/order/list/v2.0" class="ml-2">
              <v-icon color="info">arrow_back</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" sm="2">
            <v-btn
              text
              color="info"
              :to="`/order/view/${uid}/${orderId}`"
              class="ml-2"
            >
              Legacy View
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <OrderAdmin :order="myOrder" @showRefundBox="showRefundBox" />
      <v-row class="mt-4">
        <v-col
          ><v-btn @click="producerView = !producerView"
            >Back to order</v-btn
          ></v-col
        >
      </v-row>
    </template>
    <delete-confirmation-dialog
      ref="resubmitOnfirmationDialog"
      heading="Resbumit Delivery Request"
      message="This is going to create another thirdparty pickup? Are you sure you want to submit request again?"
      @onConfirm="resubmitDelivery"
    >
    </delete-confirmation-dialog>
    <delete-confirmation-dialog
      ref="resubmitOrderDialog"
      heading="Resbumit as a New Order"
      message="This is going to create a new order and submit again to this prodicer?"
      @onConfirm="resubmitOrder"
    >
    </delete-confirmation-dialog>
    <delete-confirmation-dialog
      ref="robotCallDialog"
      heading="Robot Call"
      message="This will initiate a robot call"
      @onConfirm="robotCall"
    >
    </delete-confirmation-dialog>
    <!-- order reject dialog -->
    <v-dialog v-model="rejectDialog" persistent max-width="370">
      <v-card>
        <v-card-title>Reject Order</v-card-title>
        <v-card-text>
          <v-text-field
            maxlength="250"
            label="Reason"
            v-model="response"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            text
            @click.stop="rejectDialog = false"
            >Close</v-btn
          >
          <v-btn
            dark
            depressed
            color="red"
            @click.stop="changeOrderStatus('rejecting')"
          >
            Reject
            <v-icon dark right>cancel</v-icon>!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <message-customer-dialog
      ref="messageCustomerDialog"
      :order="myOrder"
    ></message-customer-dialog>
    <track-delivery-dialog
      ref="trackDeliveryDialog"
      :delivery="myOrder.delivery"
      :track_url="track_url"
    ></track-delivery-dialog>
    <order-fee-dialog
      ref="orderFeeDialog"
      :all_fees="all_fees"
      :is_chef="true"
    ></order-fee-dialog>
    <order-ready-dialog
      ref="orderReadyDialog"
      :order="myOrder"
    ></order-ready-dialog>
    <v-snackbar :timeout="6000" color="takein" v-model="readySnackbar">
      Notification Successfully Sent
      <v-btn color="white" text @click="readySnackbar = false">Ok</v-btn>
    </v-snackbar>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Cancel Delivery"
      message="Are you sure you want to submit request to cancel delivery?"
      @onConfirm="cancelDelivery"
    >
    </delete-confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FoodView from 'fod-core/src/components/order/OrderFoodView.vue'
import ViewModifiers from 'fod-core/src/components/dish/ViewModifiers'

import { OrderMixin } from 'fod-core/src/mixins/orderMixin'

import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
//import ExtraItems from '@/components/cook/public/ExtraItemsView'

import moment from 'moment'
// import coupon from 'coupon-code'

export default {
  name: 'ViewOrder',
  mixins: [FoodMixin, OrderMixin],
  components: {
    FoodView,
    ViewModifiers,
    messageCustomerDialog: () => import('./MessageCustomerDialog.vue'),
    orderReadyDialog: () =>
      import('fod-core/src/components/order/OrderReadyDialog.vue'),
    orderFeeDialog: () =>
      import('fod-core/src/components/order/OrderFeeDialog.vue'),
    trackDeliveryDialog: () =>
      import('fod-core/src/components/order/TrackDeliveryDialog.vue'),
    OrderAdmin: () => import('./OrderAdmin'),
    RefundOrder: () => import('./RefundOrder'),
    ExtraCharge: () => import('./ExtraCharge')
  },
  data: () => ({
    uid: null,
    orderId: null,
    acceptApplied: false,
    rejectDialog: false,
    hasEnoughAmount: true,
    response: null,
    selected_order: null,
    readySnackbar: false,
    producerView: false
  }),
  computed: {
    ...mapGetters({
      myOrder: 'order',
      user: 'user',
      isAdmin: 'isAdmin',
      dishList: 'dishList',
      userProfile: 'userProfile',
      bankAccount: 'bankAccount',
      profile: 'profile',
      loading: 'loading'
    }),
    chef() {
      return this.myOrder ? this.myOrder.seller : null
    },
    isAcceptDisabled() {
      //return false
      return !this.bankAccount || !this.hasEnoughAmount
    },
    dishListAsMap() {
      var map = new Object() // or var map = {};
      this.dishList.forEach(dish => {
        map[dish.id] = dish
      })
      return map
    },
    parsedNumber() {
      const p = parsePhoneNumberFromString(this.myOrder.buyer.phone, 'US')
      if (p && p.isValid()) {
        return p.format('NATIONAL')
      }
      return this.myOrder.buyer.phone
    },
    all_fees() {
      return this.myOrder.payment.all_fees
    },
    viewLink() {
      return `https://${process.env.VUE_APP_DOMAIN}`
    },
    isSelfDelivering() {
      return (
        this.myOrder.deliveryMethod === 'delivery' &&
        this.myOrder.seller.settings.canDeliver
      )
    },
    track_url() {
      return this.myOrder.delivery &&
        this.myOrder.delivery.delivery_track &&
        this.myOrder.delivery.delivery_track.kind === 'delivery'
        ? `https://postmates.com/track/${this.myOrder.delivery.delivery_track.id}`
        : null
    },
    pickupReadyTime() {
      return moment
        .utc(this.myOrder.pickupReadyTime)
        .local()
        .format('hh:mmA MM/DD/YYYY')
    }
  },
  watch: {
    myOrder: function() {
      let action = this.$route.query.action
      console.log(`${action} Appling`, this.myOrder)
      if (
        action &&
        action.toLowerCase() === 'accept' &&
        this.myOrder &&
        this.myOrder.status === 'new' &&
        this.acceptApplied == false
      ) {
        console.log('Accepting order')
        this.acceptOrder()
      }
    }
  },
  mounted: async function() {
    let source = this.$route.query.source
    let action = this.$route.query.action
    this.uid = this.$route.params.uid
    this.orderId = this.$route.params.order_id
    console.log(this.userProfile, this.chef, this.myOrder, 'this.userProfile')
    console.log(
      `Loading order: ${this.$route.params.order_id} ,  source:${source} action:${action}`
    )
    await this.$store.dispatch('loadDishList', { force: true })

    if (this.orderId) {
      await this.$store
        .dispatch('loadOrder', { uid: this.uid, orderId: this.orderId, source })
        .then(observer => {
          this.observer = observer
          this.loadOrder()
          /*

          if (
            action &&
            action.toLowerCase() === 'accept' &&
            this.myOrder &&
            this.myOrder.status === 'new'
          ) {
            console.log('Accepting order')
            this.acceptOrder()
          }*/
        })
    } else {
      this.$store.dispatch('setError', {
        message: 'Unable to load order: ' + this.orderId
      })
    }
  },
  methods: {
    onResubmitDelivery() {
      this.$refs.resubmitOnfirmationDialog.openDialog()
    },
    onCancelDelivery() {
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    resubmitDelivery() {
      this.$refs.resubmitOnfirmationDialog.close()
      console.log('Resubmitting Delivery:', this.myOrder.orderId)

      this.$store.dispatch('resubmitDeliveryRequest', {
        id: this.myOrder.orderId,
        uid: this.uid
      })
      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    },
    onResubmitOrder() {
      this.$refs.resubmitOrderDialog.openDialog()
    },
    resubmitOrder() {
      this.$refs.resubmitOrderDialog.close()
      console.log('Resubmitting Order:', this.myOrder.orderId)

      let request = {
        id: this.$route.params.order_id,
        uid: this.myOrder.buyerUserId,
        action: 'resubmitting',
        collection: 'buyer-orders'
      }

      this.$store.dispatch('setOrderStatus', request)

      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    },
    onRobotCall() {
      this.$refs.robotCallDialog.openDialog()
    },
    robotCall() {
      this.$refs.robotCallDialog.close()
      console.log('Resubmitting Order:', this.myOrder.orderId)

      let request = {
        id: this.$route.params.order_id,
        uid: this.myOrder.sellerUserId,
        action: 'robotCall',
        collection: 'seller-orders'
      }

      this.$store.dispatch('setOrderStatus', request)

      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    },
    loadOrder() {
      if (this.myOrder) {
        this.cooking_status = this.myOrder.cooking_status
        this.checkInventory()
        if (!this.myOrder.seller) {
          if (this.myOrder.chefId && this.user.id !== this.myOrder.chefId) {
            this.$store.dispatch('loadChefById', this.myOrder.chefId)
            this.buyer = true
          }
        }

        if (
          this.myOrder.buyerUserId &&
          this.user.id !== this.myOrder.buyerUserId
        ) {
          this.buyer = false
          this.$store.dispatch('loadUserProfile', {
            uid: this.myOrder.buyerUserId
          })
        }
      }
    },
    // format modifier list
    // @param: myOrder.products.modifiers
    modifierList(modifiers) {
      let my = []
      if (modifiers) {
        Object.values(modifiers).forEach(item => {
          if (item.modifier.options) {
            let selected = item.modifier.options.filter(option =>
              item.selected.map(i => `${i}`).includes(`${option.id}`)
            )
            if (selected) {
              my = my.concat(selected)
            }
          }
        })
      }
      return my
    },
    // change status of order
    // @param: {'accepting', 'rejecting'}
    changeOrderStatus(status) {
      let request = {
        response: this.response,
        id: this.$route.params.order_id,
        uid: this.uid,
        status: status
      }

      if (status === 'accepting') {
        this.acceptApplied = true
        request.acceptDate = moment().format()
      }

      this.rejectDialog = false
      this.$store.dispatch('setOrderStatus', request)
      this.$router.push('/order/list/v2.0')
    },
    // checks if product has enough to accommodate order
    checkInventory() {
      if (this.myOrder.products) {
        for (let index = 0; index < this.myOrder.products.length; index++) {
          const item = this.myOrder.products[index]
          let dish = this.dishListAsMap[item.dish.id]
          item.dish = dish
          this.myOrder.products[index] = item
          if (
            item.dish &&
            item.dish.amountLeft < item.orderAmount &&
            item.dish.amountLeft != -1
          ) {
            this.hasEnoughAmount = false
          }
        }
      }
    },
    // convert given amount to minutes and save it to lead time
    // @params: amount of lead time in minutes (10, -20, etc)
    changeLeadTime(amount) {
      const milli = amount * 60000
      console.log('Adding ' + milli + 'to lead time')
      let request = {
        id: this.$route.params.order_id,
        leadTime: milli
      }
      this.myOrder.leadTime = milli
      this.$store.dispatch('setOrderStatus', request)
    },
    // @params: {'ready'}
    updateCookingStatus(status) {
      console.log('Saving cooking status: ', status)
      this.$store
        .dispatch('setCookingStatus', {
          id: this.$route.params.order_id,
          cooking_status: status
        })
        .then(() => {
          this.$store.dispatch('setMessage', {
            title: 'Cooking Status',
            body: 'saved'
          })
        })
    },
    showReadyForDelivery() {
      return (
        this.myOrder.deliveryMethod === 'delivery' &&
        this.myOrder.status === 'accepted' &&
        this.profile.settings &&
        this.profile.settings.arrangeThirdPartyDelivery
      )
    },
    onReadyForDelivery() {
      this.$refs.orderReadyDialog.openDialog()
    },
    convertTime(utc) {
      if (moment(utc).isSame(moment(), 'day')) {
        return moment(utc).format('hh:mm A')
      }
      return `${moment(utc).format('hh:mm A')}  (${moment(utc).fromNow()})`
    },
    onReadyForPickup() {
      this.readySnackbar = true

      let request = {
        uid: this.uid,
        id: this.$route.params.order_id,
        cooking_status: 'ready',
        pickupReadyTime: moment.utc().valueOf()
      }
      this.$store.dispatch('setCookingStatus', request)
    },
    cancelDelivery() {
      this.$refs.deleteConfirmationDialog.close()
      console.log('Canceling Delivery:', this.myOrder.orderId)

      this.$store.dispatch('setDeliveryRequest', {
        id: this.myOrder.orderId,
        uid: this.uid,
        code: 'cancelling'
      })
      this.$store.dispatch('setMessage', {
        title: 'Request Submitted',
        body: 'Thanks'
      })
    }
  }
}
</script>
<style scoped>
.status_title {
  font-size: 1em;
}
.fsx-12 {
  font-size: 0.8em;
}
</style>
